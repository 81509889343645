<template>
  <div
    :class="[
      'rounded-circle logo',
      hasPadding && width <= 40 ? 'logo-sm' : '',
      hasPadding && width > 40 && width < 100 ? 'logo-md' : '',
      hasPadding && width >= 100 ? 'logo-lg' : ''
    ]"
  >
    <img :src="src" :alt="alt" :width="width" />
  </div>
</template>

<script>
export default {
  name: 'RoundImage',
  props: {
    src: { type: String, required: true },
    alt: { type: String, required: true },
    width: { type: Number, required: true },
    hasPadding: { type: Boolean, default: true }
  }
}
</script>

<style scoped>
.logo {
  display: inline-block;
  border: 2px solid #e0e0e0;
  overflow: hidden;
  background-color: #ffffff;
}
.logo-sm {
  padding: 8px;
}
.logo-md {
  padding: 12px;
}
.logo-lg {
  padding: 16px;
}
</style>
