import axios from 'axios'

class CapacityService {
  getAllByDevice(code) {
    return axios.get(`/capacities/${code}`)
  }
  getCSV(code) {
    return axios.get(`/capacities/${code}/csv`)
  }
  getCSVByOrg(oid) {
    return axios.get(`/capacities/org/${oid}/csv`)
  }
}

export default new CapacityService()
