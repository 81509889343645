<template>
  <div>
    <Alert :alert="alert" />

    <div v-if="!submitted">
      <div class="row">
        <label class="col-md-4" for="oldPassword">Vecchia password</label>
        <div class="col-md-8">
          <input
            type="password"
            id="oldPassword"
            name="oldPassword"
            v-model="oldPassword"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="newPassword">Nuova password</label>
        <div class="col-md-8">
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            v-model="newPassword"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="repeatPassword">Ripeti password</label>
        <div class="col-md-8">
          <input
            type="password"
            id="repeatPassword"
            name="repeatPassword"
            v-model="repeatPassword"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <div class="offset-md-4">
          <button @click="save" class="btn btn-outline-success">Salva</button>
        </div>
      </div>
    </div>

    <div v-else>
      <h4>Password modificata correttamente!</h4>
    </div>
  </div>
</template>

<script>
/* Components */
import Alert from '@/components/_shared/Alert.vue'

/* services */
import UserService from '@/services/user.service'

export default {
  name: 'ChangePassword',
  components: { Alert },
  props: {
    selectedName: String,
    selected: Number
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      repeatPassword: null,
      submitted: false,
      alert: {}
    }
  },
  methods: {
    save() {
      UserService.changePassword(this.selected, {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        repeatPassword: this.repeatPassword
      })
        .then(() => {
          this.submitted = true
          this.alert = {}
          this.$emit('refresh', {
            type: 'success',
            message: 'Password cambiata con successo.'
          })
        })
        .catch(err => {
          if (err.response) {
            this.alert = {
              type: 'danger',
              message: err.response.data.error
            }
          }
        })
    }
  }
}
</script>
