<template>
  <div>
    <div v-if="!submitted">
      <div class="row">
        <label class="col-md-4" for="name">Nome</label>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="name"
            required
            v-model="org.name"
            name="name"
            @keyup.enter="saveOrg()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="logo">Logo</label>
        <div class="col-md-8">
          <input
            class="form-control"
            id="logo"
            required
            v-model="org.logo"
            name="logo"
            @keyup.enter="saveOrg()"
          />
        </div>
      </div>

      <div class="row">
        <div class="offset-md-4">
          <button @click="saveOrg" class="btn btn-success">Salva</button>
        </div>
      </div>
    </div>
    <div v-else>
      <h4>
        Organizzazione {{ isNew ? 'creata' : 'modificata' }} correttamente!
      </h4>
    </div>
  </div>
</template>

<script>
// Services
import OrgService from '@/services/org.service'

export default {
  name: 'OrganizationEdit',
  props: {
    time: Date,
    selected: Number,
    isNew: Boolean
  },
  data() {
    return {
      org: {},
      submitted: false
    }
  },
  watch: {
    time: function() {
      this.initForm()
    }
  },
  mounted() {
    this.initForm()
  },
  methods: {
    initForm() {
      this.submitted = false
      if (this.isNew) {
        this.org = {}
      } else {
        this.loadOrg()
      }
    },
    loadOrg() {
      OrgService.get(this.selected)
        .then(({ data }) => {
          this.org = data
        })
        .catch(e => {
          console.log(e)
        })
    },
    saveOrg() {
      var data = {
        name: this.org.name,
        logo: this.org.logo
      }
      if (this.isNew) {
        // create a new organization
        OrgService.create(data)
          .then(res => {
            this.org.id = res.data.id
            console.log('create org', res.data)
            this.submitted = true
            this.$emit('refresh', {
              type: 'success',
              message: 'Organizzazione creata con successo'
            })
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        // update the org
        OrgService.update(this.org.id, this.org)
          .then(res => {
            console.log('edit org', res.data)
            this.submitted = true
            this.$emit('refresh', {
              type: 'success',
              message: 'Organizzazione modificata con successo'
            })
          })
          .catch(e => {
            console.log(e)
            this.$emit('refresh', {
              type: 'danger',
              message: "Errore nel salvataggio dell'organizzazione"
            })
          })
      }
    }
  }
}
</script>
