<template>
  <div class="card border-top-0">
    <div class="card-body">
      <div class="row">
        <div class="col-md">
          <h4>Log utilizzo</h4>
        </div>
        <div class="col-md">
          <button class="btn btn-sm btn-warning float-end" @click="getURL()">
            <font-awesome-icon :icon="['fas', 'arrow-down']" />
            CSV
          </button>
        </div>
      </div>
      <div v-if="code && !isLoadingCapacities">
        <div v-if="capacities && capacities.length > 0" class="row">
          <!-- <div class="col-md-6">
            <CapacityGraph :capacities="capacities" :time="time" />
          </div> -->
          <div class="col-md-12">
            <CapacityLog :capacities="capacities" :time="time" />
          </div>
        </div>
        <p v-else class="p-3">Non ci sono misure.</p>
      </div>
      <p v-else class="p-3">
        <font-awesome-icon :icon="['fas', 'spinner']" />
        Caricamento...
      </p>
    </div>
  </div>
</template>

<script>
/* Services */
import CapacityService from '@/services/capacity.service'

/* Components */
//import CapacityGraph from '@/components/capacities/CapacityGraph.vue'
import CapacityLog from '@/components/capacities/CapacityLog.vue'

export default {
  name: 'CapacityStats',
  components: {
    //CapacityGraph,
    CapacityLog
  },
  data() {
    return {
      isLoadingCapacities: true,
      capacities: []
    }
  },
  props: {
    code: String,
    time: Date
  },
  watch: {
    time: function() {
      this.getCapacities()
    }
  },
  created() {
    this.getCapacities()
  },
  methods: {
    getCapacities: function() {
      CapacityService.getAllByDevice(this.code)
        .then(({ data }) => {
          console.log('capacities', data.capacities)
          this.capacities = data.capacities
          this.isLoadingCapacities = false
        })
        .catch(err => {
          console.log('err', err)
          this.isLoadingTemperatures = true
        })
    },
    getURL() {
      const today = new Date().toISOString().slice(0, 10)
      const filename = `${today}-dev-${this.code}_capacities.csv`
      return CapacityService.getCSV(this.code).then(res => {
        const anchor = document.createElement('a')
        anchor.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(res.data)
        anchor.target = '_blank'
        anchor.download = filename
        anchor.click()
      })
    }
  }
}
</script>

<style lang="scss">
.card-body {
  height: 60vh;
  overflow-y: scroll;
}
</style>
