<template>
  <div id="profile" class="page">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
        <div class="card">
          <div class="card-body">
            <h3>{{ userName }}</h3>
            <hr />
            <UserDetail v-if="userID" :selected="userID" :isMe="true" />
            <hr />
            <div class="float-end">
              <a
                @click="changePwd()"
                class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
              >
                <font-awesome-icon :icon="['fas', 'key']" /> Cambia password
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UserModal
      :time="time"
      :selected="userID"
      :selectedName="userName"
      :isMe="true"
      :isChangePwd="true"
      @refresh="refresh"
    />
    <Alert :alert="alert" />
  </div>
</template>

<script>
/* Components */
import Alert from '@/components/_shared/Alert.vue'
import UserDetail from '@/components/users/UserDetail.vue'
import UserModal from '@/components/users/UserModal.vue'

export default {
  name: 'Users',
  components: {
    Alert,
    UserDetail,
    UserModal
  },
  data() {
    return {
      userID: 0,
      userName: '',
      time: null,
      alert: {}
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.userID = user.id
    this.userName = user.firstName + ' ' + user.lastName
  },
  methods: {
    refresh(alert) {
      this.alert = alert
    },
    changePwd() {
      this.time = new Date()
    }
  }
}
</script>
