<template>
  <div id="app">
    <app-nav />
    <router-view />
  </div>
</template>

<script>
import AppNav from '@/components/_shared/AppNav.vue'

export default {
  components: { AppNav }
}
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';
</style>
