<template>
  <div>
    <div class="float-start">
      <RoundImage :src="org.logo" :alt="'Logo ' + org.name" :width="24" />
    </div>
    <div class="org-name">
      {{ org.name }}
    </div>
  </div>
</template>

<script>
/* Components */
import RoundImage from '@/components/_shared/RoundImage'

export default {
  name: 'OrganizationCard',
  components: {
    RoundImage
  },
  props: {
    org: {}
  }
}
</script>

<style>
.org-name {
  padding-top: 8px;
  padding-left: 60px;
}
</style>
