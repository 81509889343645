<template>
  <div>
    <div class="row flex-grow-1 h-100">
      <div class="col-md-12 device-list">
        <button v-if="isAdmin" class="button my-3" @click="create()">
          <!-- <font-awesome-icon :icon="['fas', 'plus-square']" class="me-2" /> -->
          <font-awesome-icon :icon="['fas', 'plus-circle']" class="me-2" />
          Aggiungi
        </button>
        <div>
          <p v-if="!devices || devices.length == 0">
            Nessun dispositivo trovato.
          </p>
          <table v-else class="table bg-white">
            <thead>
              <tr>
                <th>Bidone</th>
                <th>Azienda</th>
                <th class="text-end">&deg;C</th>
                <th class="text-end">%</th>
                <th class="text-center">Stato</th>
                <th>Aggiornamento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="device in devices" :key="device.id">
                <td>
                  <div class="float-start me-3">
                    <img
                      :src="getImage(device.capacity, device.isOk)"
                      :alt="device.name"
                      width="64"
                    />
                  </div>
                  <div>
                    {{ device.name }}<br />
                    <sub>{{ device.code }}</sub>
                  </div>
                </td>
                <td>
                  <OrgTag
                    v-if="device.organization"
                    :org="device.organization"
                  />
                  <span v-else>-</span>
                </td>
                <td class="text-end text-nowrap">
                  {{
                    device.temperature != null
                      ? `${device.temperature} &deg;C`
                      : `-`
                  }}
                </td>
                <td class="text-end">
                  {{ device.capacity != null ? `${device.capacity}%` : `-` }}
                </td>
                <td class="text-center">
                  <font-awesome-icon
                    v-if="!device.isOk"
                    class="text-warning"
                    :icon="['fas', 'exclamation-triangle']"
                  />
                  <font-awesome-icon
                    v-else
                    class="text-success"
                    :icon="['fas', 'check']"
                  />
                </td>
                <td>{{ formatDateAndTime(device.updatedAt) }}</td>
                <td class="text-end text-nowrap">
                  <a
                    @click="view(device)"
                    class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                  >
                    <font-awesome-icon :icon="['fas', 'eye']" />
                    <!-- Visualizza -->
                  </a>
                  <a
                    @click="edit(device.id)"
                    class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                  >
                    <font-awesome-icon :icon="['fas', 'edit']" />
                    <!-- Modifica -->
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <DeviceModal
      :time="time"
      :selected="selected"
      :device="selectedDevice"
      :is-new="isNew"
      :is-edit="isEdit"
      @refresh="refresh"
    />
    <Alert :alert="alert" />
  </div>
</template>

<script>
/* Libraries */
import { authComputed } from '@/store/helpers.js'

/* Helpers */
import FormatHelper from '@/helpers/format.helper'

/* Services */
import DeviceService from '@/services/device.service'

/* Components */
import Alert from '@/components/_shared/Alert.vue'
import DeviceModal from '@/components/devices/DeviceModal.vue'
import OrgTag from '@/components/orgs/OrgTag.vue'

export default {
  name: 'DeviceList',
  components: {
    Alert,
    DeviceModal,
    OrgTag
  },
  data() {
    return {
      name: '',
      devices: [],
      total: 0,
      isLoading: true,
      isNew: false,
      isEdit: false,
      selected: 0,
      selectedDevice: null,
      time: null,
      alert: {}
    }
  },
  computed: {
    ...authComputed
  },
  mounted() {
    this.loadDevices()
  },
  methods: {
    formatDateAndTime(date) {
      return FormatHelper.formatDateAndTime(date)
    },
    loadDevices: function() {
      if (this.name && this.name.length > 0) {
        console.log('searched:', this.name)
        DeviceService.findByName(this.name)
          .then(({ data }) => {
            this.devices = data.results
            this.total = data.total
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        // fetch devices from API
        DeviceService.getAll().then(({ data }) => {
          // load devices
          this.devices = data.results
          this.total = data.total
          this.isLoading = false
        })
      }
    },
    refresh(alert) {
      this.alert = alert
      this.loadDevices()
    },
    create() {
      this.selected = 0
      this.isNew = true
      this.isEdit = false
      this.time = new Date()
    },
    view(device) {
      this.selectedDevice = device
      this.isNew = false
      this.isEdit = false
      this.time = new Date()
    },
    edit(id) {
      this.selected = id
      this.isNew = false
      this.isEdit = true
      this.time = new Date()
    },
    getImage(capacity, isOk) {
      return DeviceService.getImage(capacity, isOk)
    }
  }
}
</script>

<style lang="scss" scoped>
.device-list {
  overflow-y: scroll;
  height: 100%;
}
sub {
  color: #808080;
}
</style>
