<template>
  <div class="page login">
    <Alert :alert="alert" />
    <div class="card card-default">
      <div class="card-body">
        <h2 class="display-4">Login</h2>
        <hr />
        <form @submit.prevent="login">
          <label for="email">Email:</label>
          <input v-model="email" type="email" name="email" value />

          <label for="password">Password:</label>
          <input v-model="password" type="password" name="password" value />

          <button class="button" type="submit" name="button">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Alert from '@/components/_shared/Alert.vue'

export default {
  components: { Alert },
  data() {
    return {
      email: '',
      password: '',
      alert: {}
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch('login', {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: 'Map' })
        })
        .catch(err => {
          this.alert = {
            type: 'danger',
            message: err.response.data.error
          }
        })
    }
  }
}
</script>

<style lang="scss">
.login {
  max-width: 400px;
  margin: 0 auto 0 auto;
  text-align: center;
}
</style>
