<template>
  <span class="badge bg-secondary me-1">
    {{ role.description }}
    <span class="btn-unset" v-if="idUser" @click="unsetAdmin()">X</span>
  </span>
</template>

<script>
/* Services */
import UserService from '@/services/user.service'

export default {
  name: 'RoleTag',
  props: {
    idUser: {},
    role: {}
  },
  methods: {
    unsetAdmin() {
      // give admin role to the user
      UserService.deleteRole(this.idUser, this.role.code)
        .then(() => {
          // success
          this.$emit('refresh')
        })
        .catch(e => {
          // error
          if (e.response) {
            console.log(e).response.data
            this.error = e.response.data.error
          }
        })
    }
  }
}
</script>

<style scoped>
.btn-unset {
  cursor: pointer;
}
</style>
