<template>
  <div id="home">
    <h1 class="display-4">Benvenuto in eMet</h1>
    <div v-if="!loggedIn">
      Per accedere fai
      <router-link to="/login">Login</router-link>
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'

export default {
  name: 'Home',
  computed: {
    ...authComputed
  }
}
</script>

<style lang="scss">
#home {
  padding: 64px 16px 16px 16px;
}
</style>
