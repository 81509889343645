<template>
  <div id="orgs" class="page">
    <h2 class="display-4">Organizzazioni</h2>
    <hr />
    <OrgList />
  </div>
</template>

<script>
/* Components */
import OrgList from '@/components/orgs/OrgList.vue'

export default {
  name: 'Organizations',
  components: {
    OrgList
  }
}
</script>
