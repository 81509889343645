<template>
  <div>
    <div
      class="modal fade"
      :class="show ? 'show' : 'hide'"
      id="add-user-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="add-user-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="add-user-modal-label">
              Aggiungi utente
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="toggleModal()"
            ></button>
          </div>
          <div class="modal-body">
            <Alert :alert="alert" />
            <select
              id="user"
              name="user"
              v-model="selected"
              class="form-select"
            >
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ `${user.firstName} ${user.lastName}` }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="toggleModal()"
            >
              Annulla
            </button>
            <button type="button" class="btn btn-primary" @click="save()">
              Salva
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="show ? 'show' : 'hide'"></div>
  </div>
</template>

<script>
/* Components */
import Alert from '@/components/_shared/Alert'

// Services
import OrgService from '@/services/org.service'
import UserService from '@/services/user.service'

export default {
  name: 'AddUserModal',
  components: {
    Alert
  },
  data() {
    return {
      show: false,
      alert: {},
      selected: 0,
      users: []
    }
  },
  props: {
    orgId: Number,
    time: Date
  },
  watch: {
    time: function() {
      this.toggleModal()
    }
  },
  mounted() {
    this.loadUsers()
  },
  methods: {
    toggleModal() {
      this.alert = {}
      this.show = !this.show
    },
    loadUsers() {
      UserService.getAll()
        .then(res => {
          this.users = res.data.results
        })
        .catch(err => {
          this.alert = {
            type: 'error',
            message: err.message
          }
          console.log(err)
        })
    },
    save() {
      console.log('save', this.selected)
      OrgService.addUser(this.orgId, { id_user: this.selected })
        .then(() => {
          this.show = false
          this.$emit('refresh', {
            type: 'success',
            message: 'Utente aggiunto alla organizzazione.'
          })
        })
        .catch(err => {
          this.alert = {
            type: 'error',
            message: err.message
          }
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
.show {
  display: block;
}
.hide {
  display: none;
}
</style>
