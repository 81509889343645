<template>
  <span class="badge bg-info me-1">
    {{ org.name }}
  </span>
</template>

<script>
export default {
  name: 'OrgTag',
  props: {
    org: {}
  }
}
</script>

<style scoped>
.btn-unset {
  cursor: pointer;
}
</style>
