import axios from 'axios'

class TempService {
  getAllByDevice(code) {
    return axios.get(`/temperatures/${code}`)
  }
  getCSV(code) {
    return axios.get(`/temperatures/${code}/csv`)
    //return `${process.env.VUE_APP_API_URL}/temperatures/${code}/csv`
  }
  getCSVByOrg(oid) {
    return axios.get(`/temperatures/org/${oid}/csv`)
  }
}

export default new TempService()
