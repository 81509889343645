<template>
  <div class="row">
    <div class="col-lg-8 col-md-9">
      <button class="button my-3" @click="create()">
        <font-awesome-icon :icon="['fas', 'plus-circle']" class="me-2" />
        Aggiungi
      </button>

      <Alert :alert="alert" />

      <div>
        <p v-if="!users || users.length == 0">Nessun utente trovato.</p>
        <table v-else class="table bg-white">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Ruoli</th>
              <th>Aziende</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>
                <RoleTag
                  v-for="role in user.roles"
                  :key="role.code"
                  :role="role"
                  :idUser="user.id"
                  @refresh="refresh"
                />
              </td>
              <td>
                <OrgTag
                  v-for="profile in user.profiles"
                  :key="profile.organization.id"
                  :org="profile.organization"
                />
                <!-- <div v-for="profile in user.profiles" :key="profile.id">
                  {{ profile.organization.name }}
                </div> -->
              </td>
              <td class="text-end text-nowrap">
                <a
                  @click="view(user)"
                  class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                >
                  <font-awesome-icon :icon="['fas', 'eye']" />
                  <!-- Visualizza -->
                </a>
                <a
                  @click="edit(user)"
                  class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" />
                  <!-- Modifica -->
                </a>
                <a
                  @click="setAdmin(user)"
                  class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                >
                  <font-awesome-icon :icon="['fas', 'angle-double-up']" />
                  <!-- Amministratore -->
                </a>
                <a
                  @click="changePwd(user)"
                  class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                >
                  <font-awesome-icon :icon="['fas', 'key']" />
                  <!-- Password -->
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <UserModal
      :time="time"
      :selected="selected"
      :selectedName="selectedName"
      :isMe="isMe"
      :isNew="isNew"
      :isEdit="isEdit"
      :isChangePwd="isChangePwd"
      @refresh="refresh"
    />
  </div>
</template>

<script>
/* Services */
import UserService from '@/services/user.service'

/* Components */
import Alert from '@/components/_shared/Alert.vue'
import OrgTag from '@/components/orgs/OrgTag.vue'
import RoleTag from '@/components/users/RoleTag.vue'
import UserModal from '@/components/users/UserModal.vue'

export default {
  name: 'UserList',
  components: {
    Alert,
    OrgTag,
    RoleTag,
    UserModal
  },
  data() {
    return {
      name: '',
      users: [],
      total: 0,
      isLoading: true,
      isNew: false,
      isEdit: false,
      isChangePwd: false,
      selected: 0,
      selectedName: '',
      time: null,
      alert: {},
      idUser: 0,
      isMe: false
    }
  },
  mounted() {
    // get current user ID
    const user = JSON.parse(localStorage.getItem('user'))
    this.idUser = user.id

    // load users
    this.loadUsers()
  },
  methods: {
    loadUsers: function() {
      if (this.name && this.name.length > 0) {
        console.log('searched:', this.name)
        UserService.findByName(this.name)
          .then(({ data }) => {
            this.users = data.results
            this.total = data.total
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        // fetch users from API
        UserService.getAll().then(({ data }) => {
          // load users
          this.users = data.results
          this.total = data.total
          this.isLoading = false
        })
      }
    },
    refresh(alert) {
      this.alert = alert
      this.loadUsers()
    },
    create() {
      this.selected = 0
      this.isNew = true
      this.isEdit = false
      this.isChangePwd = false
      this.time = new Date()
    },
    view(user) {
      this.selected = user.id
      this.selectedName = this.getFullname(user)
      this.isNew = false
      this.isEdit = false
      this.isChangePwd = false
      this.isMe = this.idUser == user.id
      this.time = new Date()
    },
    edit(user) {
      this.selected = user.id
      this.isNew = false
      this.isEdit = true
      this.isChangePwd = false
      this.isMe = this.idUser == user.id
      this.time = new Date()
    },
    changePwd(user) {
      this.selected = user.id
      this.selectedName = this.getFullname(user)
      this.isNew = false
      this.isEdit = false
      this.isChangePwd = true
      this.time = new Date()
    },
    setAdmin(user) {
      // give admin role to the user
      UserService.addRole(user.id, { role: 'ADM' })
        .then(res => {
          // success
          console.log(res)
          this.loadUsers()
        })
        .catch(e => {
          // error
          if (e.response) {
            this.alert = {
              type: 'danger',
              message: e.response.data.error
            }
          }
          console.log(e)
        })
    },
    getFullname(user) {
      return user.firstName + ' ' + user.lastName
    }
  }
}
</script>
