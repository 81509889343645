<template>
  <div class="card border-top-0">
    <div class="card-body">
      <div class="row">
        <div class="col-md">
          <h4>Log temperature</h4>
        </div>
        <div class="col-md">
          <button class="btn btn-sm btn-warning float-end" @click="getURL()">
            <font-awesome-icon :icon="['fas', 'arrow-down']" />
            CSV
          </button>
        </div>
      </div>
      <div v-if="code && !isLoadingTemperatures">
        <div v-if="temperatures && temperatures.length > 0" class="row">
          <!-- <div class="col-md-6">
            <TemperatureGraph :temperatures="temperatures" :time="time" />
          </div> -->
          <div class="col-md-12">
            <TemperatureLog :temperatures="temperatures" :time="time" />
          </div>
        </div>
        <p v-else class="p-3">Non ci sono misure.</p>
      </div>
      <p v-else class="p-3">
        <font-awesome-icon :icon="['fas', 'spinner']" />
        Caricamento...
      </p>
    </div>
  </div>
</template>

<script>
/* Services */
import TempService from '@/services/temp.service'

/* Components */
//import TemperatureGraph from '@/components/temperatures/TemperatureGraph.vue'
import TemperatureLog from '@/components/temperatures/TemperatureLog.vue'

export default {
  name: 'TemperatureStats',
  components: {
    //TemperatureGraph,
    TemperatureLog
  },
  data() {
    return {
      isLoadingTemperatures: true,
      temperatures: []
    }
  },
  props: {
    code: String,
    time: Date
  },
  watch: {
    time: function() {
      this.getTemperatures()
    }
  },
  created() {
    this.getTemperatures()
  },
  methods: {
    getTemperatures: function() {
      TempService.getAllByDevice(this.code)
        .then(({ data }) => {
          this.temperatures = data.temperatures
          this.isLoadingTemperatures = false
        })
        .catch(err => {
          console.log('err', err)
          this.isLoadingTemperatures = true
        })
    },
    getURL() {
      const today = new Date().toISOString().slice(0, 10)
      const filename = `${today}-dev-${this.code}_temperatures.csv`
      return TempService.getCSV(this.code).then(res => {
        //let blob = new Blob([res.data], { type: 'text/csv' })
        const anchor = document.createElement('a')
        anchor.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(res.data)
        anchor.target = '_blank'
        anchor.download = filename
        anchor.click()
      })
    }
  }
}
</script>

<style lang="scss">
.card-body {
  height: 60vh;
  overflow-y: scroll;
}
</style>
