<template>
  <div v-if="device">
    <Alert :alert="alert" />

    <div class="row">
      <div class="col-md-4">
        <img
          :src="getImage(device.capacity, device.isOk)"
          :alt="device.name"
          width="128"
          class="mb-3"
        />
        <div v-if="isAdmin">
          <button
            class="btn btn-sm btn-block btn-outline-danger"
            @click="deleteDevice()"
          >
            <font-awesome-icon :icon="['fas', 'trash']" class="me-2" />
            Elimina
          </button>
          <ConfirmModal
            v-if="confirmOpen"
            :time="time"
            :message="confirmMessage"
            @confirm="confirm"
          />
        </div>
      </div>
      <div class="col-md-8">
        <!-- general -->
        <div class="row">
          <div class="col-md-6"><strong>Codice</strong></div>
          <div class="col-md-6">{{ device.code }}</div>
        </div>
        <div class="row">
          <div class="col-md-6"><strong>Password</strong></div>
          <div class="col-md-6">{{ device.password }}</div>
        </div>
        <div class="row">
          <div class="col-md-6"><strong>Status</strong></div>
          <div class="col-md-6">
            <font-awesome-icon
              v-if="!device.isOk"
              class="text-warning"
              :icon="['fas', 'exclamation-triangle']"
            />
            <font-awesome-icon
              v-else
              class="text-success"
              :icon="['fas', 'check']"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"><strong>Richiesta posizione</strong></div>
          <div class="col-md-6">
            {{ device.needPosition ? 'S&igrave;' : 'No' }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"><strong>Frequenza riavvio</strong></div>
          <div class="col-md-6">
            {{ formatTime(device.rebootFrequency) }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <strong>Organizzazione</strong>
          </div>
          <div class="col-md-6">
            {{ device.organization ? device.organization.name : '-' }}
          </div>
        </div>

        <hr />

        <!-- measures -->
        <div class="row">
          <div class="col-md-6"><strong>Utilizzo</strong></div>
          <div class="col-md-6">
            <div class="clearfix">
              <font-awesome-icon
                class="float-start me-2"
                :icon="['fas', 'window-maximize']"
              />
              <div class="float-end">
                {{ device.capacity != null ? `${device.capacity}%` : `-` }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6"><strong>Temperatura</strong></div>
          <div class="col-md-6">
            <div class="clearfix">
              <font-awesome-icon
                class="float-start me-2"
                :icon="['fas', 'thermometer-half']"
              />
              <div class="float-end">
                {{
                  device.temperature != null
                    ? `${device.temperature} &deg;C`
                    : `-`
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"><strong>Posizione</strong></div>
          <div class="col-md-6">
            <div class="clearfix">
              <font-awesome-icon
                class="float-start me-2"
                :icon="['fas', 'map-marker-alt']"
              />
              <div class="float-end">
                {{
                  device.latitude != null && device.longitude != null
                    ? `${device.latitude.toFixed(6)},
                    ${device.longitude.toFixed(6)}`
                    : `-`
                }}
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!-- updated -->
        <div class="row">
          <div class="col-md-6"><strong>Creato</strong></div>
          <div class="col-md-6">
            {{ getFormattedDate(device.createdAt) }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"><strong>Modificato</strong></div>
          <div class="col-md-6">
            {{ getFormattedDate(device.updatedAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Libraries */
import { authComputed } from '@/store/helpers.js'

/* Helpers */
import FormatHelper from '@/helpers/format.helper'

/* Services */
import DeviceService from '@/services/device.service'

/* Components */
import Alert from '@/components/_shared/Alert.vue'
import ConfirmModal from '@/components/_shared/ConfirmModal'

export default {
  name: 'DeviceDetails',
  components: { Alert, ConfirmModal },
  props: {
    idDevice: Number
  },
  data() {
    return {
      device: {},
      alert: {},
      confirmOpen: false,
      confirmMessage: 'Sei sicuro di voler eliminare questo dispositivo?'
    }
  },
  watch: {
    idDevice: function() {
      this.loadDevice()
    }
  },
  computed: {
    ...authComputed
  },
  mounted() {
    this.loadDevice()
  },
  methods: {
    loadDevice() {
      // load device
      DeviceService.get(this.idDevice)
        .then(({ data }) => {
          this.device = data
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getFormattedDate(date) {
      return FormatHelper.formatDateAndTime(date)
    },
    formatTime(minutes) {
      return DeviceService.formatTime(minutes)
    },
    getImage(capacity, isOk) {
      return DeviceService.getImage(capacity, isOk)
    },
    deleteDevice() {
      this.time = new Date()
      this.confirmOpen = true
    },
    confirm() {
      this.confirmOpen = false
      console.log('confirm')
      DeviceService.delete(this.device.id)
        .then(() => {
          this.device = {}
          this.$emit('refresh', {
            type: 'success',
            message: 'Dispositivo eliminato.'
          })
        })
        .catch(err => {
          if (err.response) {
            this.alert = {
              type: 'danger',
              message: err.response.data.error
            }
          }
        })
    }
  }
}
</script>

<style scoped>
.logo {
  display: inline-block;
}
</style>
