<template>
  <!-- <div class="page" id="devices"> -->
  <div v-if="device" class="text-start">
    <h3 v-if="showTitle">{{ device.name }}</h3>
    <b-tabs content-class="device-stats-tabs">
      <b-tab title="Dettagli" active>
        <div class="card border-top-0">
          <div class="card-body">
            <DeviceDetails
              v-if="device && device.id"
              :idDevice="device.id"
              class="mb-3"
              @refresh="refresh"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Utilizzo">
        <CapacityStats
          v-if="device && device.code"
          :code="device.code"
          :time="time"
        />
      </b-tab>
      <b-tab title="Temperature">
        <TemperatureStats
          v-if="device && device.code"
          :code="device.code"
          :time="time"
        />
      </b-tab>
    </b-tabs>
  </div>
  <!-- <device/div> -->
</template>

<script>
/* Components */
import DeviceDetails from '@/components/devices/DeviceDetails.vue'
import CapacityStats from '@/components/capacities/CapacityStats.vue'
import TemperatureStats from '@/components/temperatures/TemperatureStats.vue'

export default {
  name: 'Device',
  components: {
    DeviceDetails,
    CapacityStats,
    TemperatureStats
  },
  data() {
    return {
      alert: {}
    }
  },
  props: {
    device: Object,
    showTitle: Boolean,
    time: Date
  },
  methods: {
    refresh(alert) {
      console.log('device info - refresh', alert)
      this.$emit('refresh', alert)
    }
  }
}
</script>
