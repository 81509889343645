import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import Vuex from 'vuex'

import { Role } from '@/helpers/role'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${userData.token}`
    },
    CLEAR_USER_DATA(state) {
      state.user = null
      localStorage.removeItem('user')
      delete axios.defaults.headers.common['Authorization']
      router.push('/')
    }
  },
  actions: {
    login({ commit }, credentials) {
      return axios.post('/login', credentials).then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
    },
    logout({ commit }) {
      commit('CLEAR_USER_DATA')
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.user
    },
    isAdmin(state) {
      return state.user.roles && state.user.roles.includes(Role.Admin)
    },
    isOrganization(state) {
      return state.user.roles && state.user.roles.includes(Role.Organization)
    }
  }
})
