<template>
  <div class="container-fluid">
    <div class="row dashboard flex-grow-1 vh-100">
      <div class="col-lg-3 bg-white sidemenu">
        <template v-if="!isLoading">
          <div v-if="devices.length > 0" class="list-group list-group-flush">
            <DeviceListItem
              v-for="device in devices"
              class="list-group-item list-group-item-action"
              :class="{ active: selectedIndex === device.id }"
              :key="device.id"
              :device="device"
              @refresh="refresh"
              @click.native="selectDevice(device)"
            />
          </div>
          <p class="p-5" v-else>
            Nessun dispositivo trovato.
          </p>
        </template>
        <p v-else class="p-3">
          <font-awesome-icon :icon="['fas', 'spinner']" />
          Caricamento cestini...
        </p>
      </div>
      <div class="col-lg-9 p-0">
        <template v-if="!isLoading">
          <MyGoogleMap
            v-if="devices.length > 0"
            :devices="devices"
            :selected="selectedDevice"
            @refresh="refresh"
          />
          <p v-else style="margin-top: 80px; padding: 32px;">
            Mappa non caricata.
          </p>
        </template>
        <p v-else class="p-3">
          <font-awesome-icon :icon="['fas', 'spinner']" />
          Caricamento mappa...
        </p>
        <!-- <img src="@/assets/img/bin64-half-warn.png" alt="buzzo" /> -->
      </div>
    </div>
  </div>
</template>

<script>
/* Libraries */
import axios from 'axios'

/* Components */
import DeviceListItem from '@/components/devices/DeviceListItem.vue'
import MyGoogleMap from '@/components/_shared/MyGoogleMap.vue'

export default {
  name: 'Map',
  components: {
    DeviceListItem,
    MyGoogleMap
  },
  data() {
    return {
      isLoading: true,
      total: 0,
      devices: [],
      selectedDevice: null,
      selectedIndex: -1
    }
  },
  mounted() {
    this.getDevices()
  },
  methods: {
    getDevices: function() {
      axios.get('/devices').then(({ data }) => {
        this.devices = data.results
        if (this.devices.length > 0) {
          this.selectedDevice = this.devices[0]
        }
        this.isLoading = false
      })
    },
    selectDevice(selected) {
      this.selectedDevice = selected
      this.selectedIndex = selected.id
    },
    refresh(value) {
      console.log('map value', value)
      this.selectedIndex = value
    }
  }
}
</script>

<style lang="scss" scoped>
.sidemenu {
  padding: 64px 0 0 0;
  overflow-y: scroll;
  height: 100%;
}
.list-group-item {
  cursor: pointer;
}
</style>
