<template>
  <div id="users" class="page">
    <h2 class="display-4">Utenti</h2>
    <hr />
    <UserList />
  </div>
</template>

<script>
/* Components */
import UserList from '@/components/users/UserList.vue'

export default {
  name: 'Users',
  components: {
    UserList
  }
}
</script>
