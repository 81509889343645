<template>
  <div class="alert-fixed" v-if="isOpen">
    <div :class="getCss()" role="alert">
      <h4 class="alert-heading">{{ getTitle() }}</h4>
      <p>{{ alert.message }}</p>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        @click="close()"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    alert: {}
  },
  watch: {
    alert: function() {
      this.isOpen = true
    }
  },
  methods: {
    close() {
      this.isOpen = false
    },
    open() {
      this.isOpen = true
    },
    getTitle() {
      return this.alert.type == 'success'
        ? 'Perfetto!'
        : this.alert.type == 'warning'
        ? 'Attenzione!'
        : this.alert.type == 'danger'
        ? 'Errore!'
        : this.alert.type == 'info'
        ? 'Info'
        : 'Messaggio'
    },
    getCss() {
      return (
        'alert alert-dismissible fade show ' +
        (this.alert.type == 'success'
          ? 'alert-success'
          : this.alert.type == 'warning'
          ? 'alert-warning'
          : this.alert.type == 'danger'
          ? 'alert-danger'
          : this.alert.type == 'info'
          ? 'alert-info'
          : '')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-radius: 0;
  margin: 0;
  padding: 8px;
}
.alert-fixed .alert {
  margin-bottom: 0;
}
.alert-fixed p {
  padding: 0;
}
</style>
