<template>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h5>
        {{ device.name }}
        <font-awesome-icon
          v-if="!device.isOk"
          class="text-warning"
          :icon="['fas', 'exclamation-triangle']"
        />
      </h5>
      <div class="text-start">
        <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="me-2" />
        {{
          device.latitude != null && device.longitude != null
            ? `${device.latitude.toFixed(6)}, ${device.longitude.toFixed(6)}`
            : `-`
        }}
      </div>
    </div>
    <span>
      <span class="badge bg-primary rounded-pill">
        {{ device.capacity != null ? device.capacity + '%' : '-' }}
      </span>
      <span class="badge bg-info rounded-pill ms-1">
        {{ device.temperature != null ? device.temperature + ' &deg;C' : '-' }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'DeviceListItem',
  props: {
    device: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
h5 {
  text-align: left;
}
</style>
