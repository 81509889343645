<template>
  <div>
    <div v-if="!org || !org.id">
      <h4 v-if="message">{{ message }}</h4>
      <p v-else>Caricamento...</p>
    </div>
    <div v-else>
      <b-tabs content-class="org-tabs">
        <b-tab title="Dettagli" active>
          <div class="card border-top-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 text-center">
                  <RoundImage
                    :src="org.logo"
                    :alt="'Logo ' + org.name"
                    :width="100"
                    class="mb-3"
                  />
                  <div>
                    <button
                      class="btn btn-sm btn-block btn-outline-danger"
                      @click="deleteOrg()"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'trash']"
                        class="me-2"
                      />
                      Elimina
                    </button>
                    <ConfirmModal
                      v-if="confirmOpen"
                      :time="confirmTime"
                      :message="confirmMessage"
                      @confirm="confirm"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row mb-1">
                    <div class="col-md-6"><strong>Creato</strong></div>
                    <div class="col-md-6">
                      {{ getFormattedDate(org.createdAt) }}
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-6"><strong>Modificato</strong></div>
                    <div class="col-md-6">
                      {{ getFormattedDate(org.updatedAt) }}
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-6">
                      <strong>Log utilizzo</strong>
                    </div>
                    <div class="col-md-6">
                      <button
                        @click="getCapCSV()"
                        class="btn btn-sm btn-warning"
                      >
                        <font-awesome-icon :icon="['fas', 'arrow-down']" />
                        CSV
                      </button>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-6">
                      <strong>Log temperature</strong>
                    </div>
                    <div class="col-md-6">
                      <button
                        @click="getTempCSV()"
                        class="btn btn-sm btn-warning"
                      >
                        <font-awesome-icon :icon="['fas', 'arrow-down']" />
                        CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Dispositivi">
          <div class="card border-top-0">
            <div class="card-body">
              <div class="clearfix">
                <h4 class="float-start">Dispositivi</h4>
              </div>
              <p class="text-">
                {{
                  org.devices.length +
                    (org.devices.length == 1
                      ? ' dispositivo trovato.'
                      : ' dispositivi trovati.')
                }}
              </p>
              <div v-if="org.devices && org.devices.length > 0">
                <hr />
                <div class="list-group list-group-flush">
                  <div
                    class="list-group-item py-2 clearfix"
                    v-for="device in org.devices"
                    :key="device.id"
                  >
                    <span class="float-start">
                      {{ `${device.name} (${device.code})` }}
                    </span>
                    <button
                      class="btn btn-sm btn-outline-danger float-end px-2"
                      @click="removeDevice(device.id)"
                    >
                      &times;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Utenti">
          <div class="card border-top-0">
            <div class="card-body">
              <div class="clearfix">
                <h4 class="float-start">Utenti</h4>
                <button
                  class="btn btn-sm btn-outline-success float-end"
                  @click="addUser()"
                >
                  Aggiungi
                </button>
              </div>
              <p class="text-">
                {{
                  org.profiles.length +
                    (org.profiles.length == 1
                      ? ' utente trovato.'
                      : ' utenti trovati.')
                }}
              </p>
              <div v-if="org.profiles && org.profiles.length > 0">
                <hr />
                <div class="list-group list-group-flush">
                  <div
                    class="list-group-item py-2 clearfix"
                    v-for="profile in org.profiles"
                    :key="profile.id_user"
                  >
                    <span class="float-start">
                      {{ `${profile.user.firstName} ${profile.user.lastName}` }}
                    </span>
                    <button
                      class="btn btn-sm btn-outline-danger float-end px-2"
                      @click="removeUser(profile.id_user)"
                    >
                      &times;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <AddUserModal :orgId="org.id" :time="subTime" @refresh="refresh" />
    </div>
  </div>
</template>

<script>
/* Helpers */
import FormatHelper from '@/helpers/format.helper'

/* Components */
import AddUserModal from '@/components/orgs/AddUserModal'
import ConfirmModal from '@/components/_shared/ConfirmModal'
import RoundImage from '@/components/_shared/RoundImage'

/* Services */
import CapacityService from '@/services/capacity.service'
import OrgService from '@/services/org.service'
import TemperatureService from '@/services/temp.service'

export default {
  name: 'OrganizationDetail',
  components: {
    AddUserModal,
    ConfirmModal,
    RoundImage
  },
  data() {
    return {
      org: {},
      subTime: null,
      message: null,
      confirmTime: null,
      confirmOpen: false,
      confirmMessage: 'Sei sicuro di voler eliminare questa organizzazione?'
    }
  },
  props: {
    time: Date,
    selected: Number
  },
  watch: {
    time: function() {
      this.loadOrg()
    }
  },
  mounted() {
    this.loadOrg()
  },
  methods: {
    getFormattedDate(date) {
      return FormatHelper.formatDateAndTime(date)
    },
    loadOrg() {
      OrgService.get(this.selected)
        .then(({ data }) => {
          this.org = data
        })
        .catch(e => {
          console.log(e)
        })
    },
    deleteOrg() {
      this.confirmTime = new Date()
      this.confirmOpen = true
    },
    confirm() {
      this.confirmOpen = false
      OrgService.delete(this.selected)
        .then(() => {
          this.org = {}
          this.$emit('refresh', {
            type: 'success',
            message: 'Organizzazione eliminata con successo.'
          })
        })
        .catch(err => {
          if (err.response) {
            this.$emit('refresh', {
              type: 'danger',
              message: err.response.data.error
            })
          }
        })
    },
    addUser() {
      this.subTime = new Date()
    },
    removeUser(uid) {
      console.log('remove user ' + uid)
      OrgService.removeUser(this.selected, uid)
        .then(() => {
          this.org = {}
          this.message = `Utente rimosso dall'organizzazione.`
          this.$emit('refresh')
        })
        .catch(err => {
          if (err.response) {
            this.message = err.response.data.error
          }
        })
    },
    removeDevice(did) {
      console.log('remove device ' + did)
      OrgService.removeDevice(this.selected, did)
        .then(() => {
          this.org = {}
          this.message = `Dispositivo rimosso dall'organizzazione.`
          this.$emit('refresh')
        })
        .catch(err => {
          if (err.response) {
            this.message = err.response.data.error
          }
        })
    },
    getCapCSV() {
      const today = new Date().toISOString().slice(0, 10)
      const filename = `${today}_org-${this.org.id}_capacities.csv`
      return CapacityService.getCSVByOrg(this.org.id).then(res => {
        const anchor = document.createElement('a')
        anchor.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(res.data)
        anchor.target = '_blank'
        anchor.download = filename
        anchor.click()
      })
    },
    getTempCSV() {
      const today = new Date().toISOString().slice(0, 10)
      const filename = `${today}_org-${this.org.id}_temperatures.csv`
      return TemperatureService.getCSVByOrg(this.org.id).then(res => {
        const anchor = document.createElement('a')
        anchor.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(res.data)
        anchor.target = '_blank'
        anchor.download = filename
        anchor.click()
      })
    },
    refresh(alert) {
      this.loadOrg()
      this.$emit('refresh', alert)
    }
  }
}
</script>

<style scoped>
.logo {
  display: inline-block;
}
</style>
