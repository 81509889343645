<template>
  <div class="page" id="devices">
    <h2 class="display-4">Dispositivi</h2>
    <hr />
    <!-- <DeviceEdit :showModal="showModal" /> -->
    <DeviceList />
  </div>
</template>

<script>
/* Components */
// import DeviceEdit from '@/components/devices/DeviceEdit.vue'
import DeviceList from '@/components/devices/DeviceList.vue'

export default {
  name: 'Devices',
  components: {
    // DeviceEdit,
    DeviceList
  }
}
</script>
