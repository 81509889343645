//import http from '@/services/http-common'
import axios from 'axios'

class DeviceService {
  getAll() {
    return axios.get(`/devices`)
  }

  findByName(name) {
    return axios.get(`/devices?name=${name}`)
  }

  get(id) {
    return axios.get(`/device/${id}`)
  }

  create(data) {
    return axios.post(`/device`, data)
  }

  update(id, data) {
    return axios.put(`/device/${id}`, data)
  }

  delete(id) {
    return axios.delete(`/device/${id}`)
  }

  getImage(capacity, isOk) {
    let img = 'bin-c-'
    // img +=
    //   capacity <= 5
    //     ? '1'
    //     : capacity <= 35
    //     ? '2'
    //     : capacity <= 65
    //     ? '3'
    //     : capacity <= 95
    //     ? '4'
    //     : '5'
    img +=
      capacity <= 5
        ? '1'
        : capacity <= 40
        ? '2'
        : capacity <= 60
        ? '3'
        : capacity <= 80
        ? '4'
        : '5'
    img += isOk ? '.png' : '-w.png'
    return require('@/assets/img/' + img)
  }

  formatTime(minutes) {
    let output = ''

    // days
    if (minutes >= 1440) {
      const days = Math.floor(minutes / 1440)
      output += days == 1 ? `${days} giorno ` : `${days} giorni `
      minutes = minutes % 1440
    }

    // hours
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60)
      output += hours == 1 ? `${hours} ora ` : `${hours} ore `
      minutes = minutes % 60
    }

    if (minutes > 0) {
      output += minutes == 1 ? `${minutes} minuto` : `${minutes} minuti`
    }

    return output
  }
}

export default new DeviceService()
