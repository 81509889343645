<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Data</th>
        <th>Ora</th>
        <th class="text-end">Utilizzo</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="capacity in capacities"
        :key="capacity.id"
        :capacity="capacity"
      >
        <td>
          {{ formatDate(capacity.createdAt) }}
        </td>
        <td>
          {{ formatTime(capacity.createdAt) }}
        </td>
        <td class="text-end">{{ capacity.measure }}%</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* Helpers */
import FormatHelper from '@/helpers/format.helper'

export default {
  name: 'CapacityLog',
  props: {
    capacities: Array
  },
  methods: {
    formatDate: function(date) {
      return FormatHelper.formatDate(date)
    },
    formatTime: function(date) {
      return FormatHelper.formatTime(date)
    }
  }
}
</script>
