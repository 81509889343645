//import { basicClient, authClient } from '@/services/http-common'
import axios from 'axios'

class UserService {
  getAll() {
    return axios.get(`/users`)
  }

  findByName(name) {
    return axios.get(`/users?name=${name}`)
  }

  get(id) {
    return axios.get(`/user/${id}`)
  }

  create(data) {
    return axios.post(`/user`, data)
  }

  update(id, data) {
    return axios.put(`/user/${id}`, data)
  }

  changePassword(id, data) {
    return axios.put(`/user/${id}/password`, data)
  }

  delete(id) {
    return axios.delete(`/user/${id}`)
  }

  addRole(id, data) {
    return axios.post(`/user/${id}/role`, data)
  }

  deleteRole(id, role) {
    return axios.delete(`/user/${id}/role/${role}`)
  }
}

export default new UserService()
