<template>
  <!-- <div class="card">
    <div class="card-body">
      <h3>{{ (isNew ? 'Nuovo' : 'Modifica') + ' dispositivo' }}</h3>
      <hr /> -->

  <div>
    <Alert :alert="alert" />

    <div v-if="!submitted">
      <div class="row">
        <label class="col-md-4" for="name">Nome</label>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            id="name"
            required
            v-model="device.name"
            name="name"
            @keyup.enter="saveDevice()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="code">Code</label>
        <div class="col-md-8">
          <input
            type="text"
            id="code"
            name="code"
            v-model="device.code"
            class="form-control"
            :required="isAdmin"
            :disabled="!isAdmin"
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="password">Password</label>
        <div class="col-md-8">
          <input
            type="text"
            id="password"
            name="password"
            v-model="device.password"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="organization">
          Organizzazione
        </label>
        <div class="col-md-8 mb-3">
          <select
            type="text"
            id="organization"
            name="organization"
            v-model="device.id_organization"
            v-if="orgs.length > 0"
            class="form-select"
            required
            @keyup.enter="save()"
          >
            <option v-for="org in orgs" :key="org.id" :value="org.id">
              {{ org.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="latitude">Posizione</label>
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-6">
              <label for="latitude">Latitudine:</label>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                id="latitude"
                name="latitude"
                v-model="device.latitude"
                class="form-control"
                @keyup.enter="save()"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label for="longitude">Longitudine:</label>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                id="longitude"
                name="longitude"
                v-model="device.longitude"
                class="form-control"
                @keyup.enter="save()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <label class="col-md-4" for="needPosition">Chiede posizione</label>
        <div class="col-md-8">
          <div class="form-check form-switch">
            <input
              type="checkbox"
              class="form-check-input"
              id="needPosition"
              name="needPosition"
              v-model="device.needPosition"
              required
              @keyup.enter="save()"
            />
            <label class="form-check-label" for="needPosition">
              {{ device.needPosition ? 'Sì' : 'No' }}
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="rebootFrequency">Frequenza riavvio</label>
        <div class="col-md-8">
          <input
            type="text"
            id="rebootFrequency"
            name="rebootFrequency"
            v-model="device.rebootFrequency"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <div class="offset-md-4">
          <button @click="saveDevice" class="btn btn-success">Salva</button>
        </div>
      </div>
    </div>
    <div v-else>
      <h4>Dispositivo {{ isNew ? 'creato' : 'modificato' }} correttamente!</h4>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
/* Libraries */
import { authComputed } from '@/store/helpers.js'

/* Components */
import Alert from '@/components/_shared/Alert.vue'

/* services */
import DeviceService from '@/services/device.service'
import OrgService from '@/services/org.service'

export default {
  name: 'DeviceEdit',
  components: {
    Alert
  },
  props: {
    time: Date,
    selected: Number,
    isNew: Boolean
  },
  data() {
    return {
      device: {},
      orgs: [],
      submitted: false,
      alert: {}
    }
  },
  watch: {
    time: function() {
      this.initForm()
    }
  },
  computed: {
    ...authComputed
  },
  mounted() {
    this.initForm()
  },
  methods: {
    initForm() {
      this.submitted = false
      this.loadOrganizations()
      if (this.isNew) {
        this.device = {}
      } else {
        this.loadDevice()
      }
    },
    loadOrganizations() {
      OrgService.getAll()
        .then(({ data }) => {
          this.orgs = data.results
        })
        .catch(e => {
          console.log(e)
        })
    },
    loadDevice() {
      DeviceService.get(this.selected)
        .then(({ data }) => {
          this.device = data
        })
        .catch(e => {
          console.log(e)
        })
    },
    saveDevice() {
      var data = {
        name: this.device.name,
        code: this.device.code,
        password: this.device.password,
        id_organization: this.device.id_organization,
        latitude: this.device.latitude,
        longitude: this.device.longitude,
        needPosition: this.device.needPosition,
        rebootFrequency: this.device.rebootFrequency
      }
      if (this.isNew) {
        // create a new device
        DeviceService.create(data)
          .then(res => {
            this.device.id = res.data.id
            this.submitted = true
            this.$emit('refresh', {
              type: 'success',
              message: 'Il dispositivo è stato inserito con successo.'
            })
          })
          .catch(e => {
            if (e.response) {
              this.alert = {
                type: 'error',
                message: e.response.data.error
              }
            }
            console.log(e)
          })
      } else {
        // update the device
        DeviceService.update(this.device.id, data)
          .then(() => {
            this.submitted = true
            this.$emit('refresh', {
              type: 'success',
              message: 'Il dispositivo è stato modificato con successo.'
            })
          })
          .catch(e => {
            if (e.response) {
              this.alert = {
                type: 'error',
                message: e.response.data.error
              }
            }
            console.log(e)
          })
      }
    }
  }
}
</script>
