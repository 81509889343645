<template>
  <div>
    <div v-if="!user || !user.id">
      <h4 v-if="message">{{ message }}</h4>
      <p v-else>Caricamento...</p>
    </div>
    <div v-else class="row">
      <div class="col-md-4">
        <RoundImage
          :src="defaultImage"
          :alt="'Logo ' + getFullname()"
          :width="128"
          :has-padding="false"
          class="mb-3"
        />
        <div>
          <button
            v-if="!isMe"
            class="btn btn-sm btn-block btn-outline-danger"
            @click="deleteUser()"
          >
            <font-awesome-icon :icon="['fas', 'trash']" class="me-2" />
            Elimina
          </button>
        </div>
      </div>

      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4"><strong>E-Mail</strong></div>
          <div class="col-md-8">
            {{ user.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4"><strong>Ruoli</strong></div>
          <div class="col-md-8">
            <RoleTag v-for="role in user.roles" :key="role.code" :role="role" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4"><strong>Aziende</strong></div>
          <div class="col-md-8">
            <OrgTag
              v-for="profile in user.profiles"
              :key="profile.organization.id"
              :org="profile.organization"
            />
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-4"><strong>Creato</strong></div>
          <div class="col-md-8">
            {{ getFormattedDate(user.createdAt) }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-4"><strong>Modificato</strong></div>
          <div class="col-md-8">
            {{ getFormattedDate(user.updatedAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Helpers */
import FormatHelper from '@/helpers/format.helper'

/* Components */
import OrgTag from '@/components/orgs/OrgTag.vue'
import RoleTag from '@/components/users/RoleTag'
import RoundImage from '@/components/_shared/RoundImage'

/* Services */
import UserService from '@/services/user.service'

export default {
  name: 'UserDetail',
  components: {
    OrgTag,
    RoleTag,
    RoundImage
  },
  props: {
    selected: {
      type: Number,
      required: false
    },
    isMe: Boolean
  },
  data() {
    return {
      user: {},
      message: null,
      defaultImage:
        'https://icon-library.com/images/default-user-icon/default-user-icon-4.jpg'
    }
  },
  watch: {
    selected: function() {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    getFullname() {
      return this.user.firstName + ' ' + this.user.lastName
    },
    getFormattedDate(date) {
      return FormatHelper.formatDateAndTime(date)
    },
    load() {
      UserService.get(this.selected)
        .then(({ data }) => {
          this.user = data
        })
        .catch(e => {
          console.log(e)
        })
    },
    deleteUser() {
      UserService.delete(this.selected)
        .then(() => {
          this.user = {}
          this.message = 'Utente eliminato.'
          this.$emit('refresh')
        })
        .catch(err => {
          if (err.response) {
            this.message = err.response.data.error
          }
        })
    }
  }
}
</script>
