<template>
  <div id="map-box">
    <GmapMap
      id="map-content"
      :center="{ lat: this.selected.latitude, lng: this.selected.longitude }"
      :zoom="12"
    >
      <GmapMarker
        v-for="m in markers"
        :key="m.position.device.id"
        :position="m.position"
        :clickable="true"
        :icon="m.position.icon"
        @click="toggleInfoWindow(m, m.position.device.id)"
      >
        <GmapInfoWindow
          v-if="currentMidx == m.position.device.id"
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <DeviceInfo :device="m.position.device" :show-title="true" />
        </GmapInfoWindow>
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

import DeviceInfo from '@/components/devices/DeviceInfo.vue'

export default {
  name: 'MyGoogleMap',
  components: {
    DeviceInfo
  },
  props: {
    devices: {
      type: Array,
      default: () => {}
    },
    selected: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    google: gmapApi
  },
  data() {
    return {
      currentPlace: null,
      markers: [],
      places: [],
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 10,
          height: 0
        },
        minWidth: 500,
        maxHeight: 320
      }
    }
  },
  mounted() {
    this.devices.forEach(dev => {
      this.addMarker(dev)
    })
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place
    },
    getImage(capacity, isOk) {
      let img = 'bin-c-'
      img +=
        capacity <= 5
          ? '1'
          : capacity <= 35
          ? '2'
          : capacity <= 65
          ? '3'
          : capacity <= 95
          ? '4'
          : '5'
      img += isOk ? '.svg' : '-w.svg'
      return img
    },
    addMarker(device) {
      if (device && device.latitude && device.longitude) {
        let img = this.getImage(device.capacity, device.isOk)
        console.log('img', img)

        const marker = {
          lat: device.latitude,
          lng: device.longitude,
          title: device.name,
          device: device,
          icon: {
            url: require('@/assets/img/' + img),
            scaledSize: { width: 64, height: 64 }
          }
        }
        this.markers.push({ position: marker })
      }
    },
    toggleInfoWindow: function(marker, idx) {
      console.log('toggle - marker : ', marker)
      console.log('toggle - idx : ', idx)
      console.log('toggle - currentMidx : ', this.currentMidx)

      this.infoWindowPos = marker.position
      this.infoWinOpen = true
      this.currentMidx = idx

      this.$emit('refresh', this.infoWindowPos.device.id)
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(device) {
        this.selected = device
        const marker = this.markers.filter(function(m) {
          return m.position.device.id == device.id
        })
        console.log('MyGoogleMap selected:', this.selected)
        console.log('MyGoogleMap markers:', this.markers)

        if (marker.length > 0) {
          console.log('MyGoogleMap marker:', marker[0])
          this.toggleInfoWindow(marker[0], this.selected.id)
        }
      }
    }
  }
}
</script>

<style scoped>
#map-box {
  width: 100%;
  height: 100%;
}
#map-content {
  width: 100%;
  height: 100%;
  min-height: 500px;
}
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  margin-top: 32px;
}
</style>
