<template>
  <div v-if="open">
    <div class="modal-backdrop fade show"></div>
    <div
      v-if="open"
      class="modal fade show"
      id="modal-org"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-org-label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-org-label">
              {{ modalName }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            ></button>
          </div>
          <div class="modal-body">
            <OrgDetail
              v-if="!isNew && !isEdit && selected"
              :time="time"
              :selected="selected"
              @refresh="refresh"
            />
            <OrgEdit
              v-if="isNew || isEdit"
              :time="time"
              :selected="selected"
              :isNew="isNew"
              @refresh="refresh"
            />
          </div>
          <div class="modal-footer">
            <!-- Commands -->
            <div class="me-auto"></div>
            <button
              class="btn btn-outline-secondary"
              data-dismiss="modal"
              @click="closeModal()"
            >
              <font-awesome-icon :icon="['fas', 'times']" aria-label="Chiudi" />
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Components */
import OrgDetail from '@/components/orgs/OrgDetail.vue'
import OrgEdit from '@/components/orgs/OrgEdit.vue'

export default {
  name: 'OrgModal',
  components: {
    OrgDetail,
    OrgEdit
  },
  data() {
    return {
      open: false
    }
  },
  props: {
    time: Date,
    selected: Number,
    selectedName: String,
    isNew: Boolean,
    isEdit: Boolean
  },
  watch: {
    time: function() {
      this.openModal()
    }
  },
  created() {
    this.openModal()
  },
  computed: {
    modalName: function() {
      return this.isNew
        ? 'Nuovo organizzazione'
        : this.isEdit
        ? 'Modifica organizzazione'
        : this.selectedName
    }
  },
  methods: {
    openModal() {
      if (this.time) {
        this.open = true
      }
    },
    closeModal() {
      this.open = false
    },
    refresh(alert) {
      this.closeModal()
      this.$emit('refresh', alert)
    }
  }
}
</script>

<style>
.modal {
  z-index: 1070;
}
.modal-body {
  max-height: 100%;
  overflow: scroll;
}
.show {
  display: block;
}
</style>
