import axios from 'axios'

class OrgService {
  getAll() {
    return axios.get(`/organizations`)
  }

  findByName(name) {
    return axios.get(`/organizations?name=${name}`)
  }

  get(id) {
    return axios.get(`/organization/${id}`)
  }

  create(data) {
    return axios.post(`/organization`, data)
  }

  update(id, data) {
    return axios.put(`/organization/${id}`, data)
  }

  delete(id) {
    return axios.delete(`/organization/${id}`)
  }

  addUser(id, data) {
    return axios.put(`/organization/${id}/user`, data)
  }

  removeUser(oid, uid) {
    return axios.delete(`/organization/${oid}/user/${uid}`)
  }

  removeDevice(oid, did) {
    return axios.delete(`/organization/${oid}/device/${did}`)
  }
}

export default new OrgService()
