/* Libraries */
const moment = require('moment-timezone')
moment.tz.setDefault('Europe/Rome')

class FormatHelper {
  formatDate(date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
  }

  formatTime(date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
  }

  formatDateAndTime(date) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
  }
}

export default new FormatHelper()
