import Vue from 'vue'
import VueRouter from 'vue-router'

import { Role } from '@/helpers/role'

// pages
import Home from '@/views/Home.vue'

import Map from '@/views/Map.vue'

import Devices from '@/views/Devices.vue'

import Organizations from '@/views/Organizations.vue'

import Users from '@/views/Users.vue'

import Profile from '@/views/Profile.vue'
import LoginUser from '@/views/LoginUser.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: { requiresAuth: true }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: Devices,
    meta: { requiresAuth: true }
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: Organizations,
    meta: { requiresAuth: true, authorize: [Role.Admin] }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: { requiresAuth: true, authorize: [Role.Admin, Role.Organization] }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginUser
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const { authorize } = to.meta

  if (authorize) {
    if (!user) {
      // not logged => redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } })
    }

    // check if route is restricted by role
    if (authorize.length) {
      let foundRole = false
      authorize.forEach(role => {
        if (user.roles.includes(role)) {
          foundRole = true
        }
      })
      if (!foundRole) {
        // role not found => redirect to home
        return next({ path: '/' })
      }
    }
  }

  return next()
})

export default router
