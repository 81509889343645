<template>
  <div>
    <Alert :alert="alert" />

    <div v-if="!submitted">
      <div class="row">
        <label class="col-md-4" for="firstName">Nome</label>
        <div class="col-md-8">
          <input
            type="text"
            id="firstName"
            name="firstName"
            v-model="user.firstName"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="lastName">Cognome</label>
        <div class="col-md-8">
          <input
            type="text"
            id="lastName"
            name="lastName"
            v-model="user.lastName"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <label class="col-md-4" for="email">E-Mail</label>
        <div class="col-md-8">
          <input
            type="text"
            id="email"
            name="email"
            v-model="user.email"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div v-if="isNew" class="row">
        <label class="col-md-4" for="password">Password</label>
        <div class="col-md-8">
          <input
            type="password"
            id="password"
            name="password"
            v-model="user.password"
            class="form-control"
            required
            @keyup.enter="save()"
          />
        </div>
      </div>

      <div class="row">
        <div class="offset-md-4">
          <button @click="save" class="btn btn-outline-success">Salva</button>
        </div>
      </div>
    </div>

    <div v-else>
      <h4>Utente {{ isNew ? 'creato' : 'modificato' }} correttamente!</h4>
    </div>
  </div>
</template>

<script>
/* Components */
import Alert from '@/components/_shared/Alert.vue'

/* services */
import UserService from '@/services/user.service'

export default {
  name: 'UserEdit',
  components: { Alert },
  props: {
    time: Date,
    selected: Number,
    isNew: Boolean
  },
  data() {
    return {
      user: {},
      submitted: false,
      error: null
    }
  },
  watch: {
    time: function() {
      this.initForm()
    }
  },
  mounted() {
    this.initForm()
  },
  methods: {
    initForm() {
      this.submitted = false
      if (this.isNew) {
        this.user = {}
      } else {
        this.load()
      }
    },
    load() {
      UserService.get(this.selected)
        .then(({ data }) => {
          this.user = data
        })
        .catch(e => {
          console.log(e)
        })
    },
    save() {
      var data = this.user
      if (this.isNew) {
        // create a new user
        UserService.create(data)
          .then(res => {
            this.user.id = res.data.id
            console.log(res.data)
            this.submitted = true
            this.$emit('refresh', {
              type: 'success',
              message: 'Utente creato con successo.'
            })
          })
          .catch(e => {
            if (e.response) {
              this.alert = {
                type: 'danger',
                message: e.response.data.error
              }
            }
            console.log(e)
          })
      } else {
        // update the user
        UserService.update(this.user.id, this.user)
          .then(res => {
            console.log(res.data)
            this.submitted = true
            this.$emit('refresh', {
              type: 'success',
              message: 'Utente modificato con successo.'
            })
          })
          .catch(e => {
            if (e.response) {
              this.alert = {
                type: 'danger',
                message: e.response.data.error
              }
            }
            console.log(e)
          })
      }
    }
  }
}
</script>
