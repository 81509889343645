<template>
  <div v-if="open">
    <div class="modal-backdrop fade show"></div>
    <div
      v-if="open"
      class="modal fade show"
      id="modal-confirm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-confirm-label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-confirm-label">
              Conferma eliminazione
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            ></button>
          </div>
          <div class="modal-body">
            {{ message }}
          </div>
          <div class="modal-footer">
            <!-- Commands -->
            <div class="me-auto">
              <button
                class="btn btn-outline-danger"
                data-dismiss="modal"
                @click="confirm()"
              >
                Conferma
              </button>
            </div>
            <button
              class="btn btn-outline-secondary"
              data-dismiss="modal"
              @click="closeModal()"
            >
              <font-awesome-icon :icon="['fas', 'times']" aria-label="Chiudi" />
              Annulla
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data() {
    return {
      open: false
    }
  },
  props: {
    time: Date,
    message: String
  },
  watch: {
    time: function() {
      this.openModal()
    }
  },
  created() {
    this.openModal()
  },
  methods: {
    openModal() {
      if (this.time) {
        this.open = true
      }
    },
    closeModal() {
      this.open = false
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style>
.modal {
  z-index: 1070;
}
.show {
  display: block;
}
</style>
