<template>
  <div class="row">
    <div class="col-lg-8 col-md-9">
      <button class="button my-3" @click="create()">
        <font-awesome-icon :icon="['fas', 'plus-circle']" class="me-2" />
        Aggiungi
      </button>
      <div>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control mb-0"
            placeholder="Cerca per nome..."
            v-model="name"
            @keyup.enter="loadOrgs()"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="loadOrgs()"
          >
            <font-awesome-icon :icon="['fas', 'search']" class="me-2" />
            Cerca
          </button>
        </div>

        <p v-if="!orgs || orgs.length == 0">Nessun azienda trovata.</p>
        <table v-else class="table bg-white">
          <thead>
            <tr>
              <th>Azienda</th>
              <th class="text-end">Dispositivi</th>
              <th class="text-end">Utenti</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="org in orgs" :key="org.id">
              <td>
                <OrgCard :org="org" />
              </td>
              <td class="text-end">
                {{ org.devices.length }}
              </td>
              <td class="text-end">
                {{ org.profiles.length }}
              </td>
              <td class="text-end text-nowrap">
                <a
                  @click="view(org)"
                  class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                >
                  <font-awesome-icon :icon="['fas', 'eye']" />
                  <!-- Visualizza -->
                </a>
                <a
                  @click="edit(org)"
                  class="btn btn-sm btn-outline-secondary me-1 mb-1 px-3"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" />
                  <!-- Modifica -->
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <OrgModal
      :time="time"
      :selected="selected"
      :selectedName="selectedName"
      :isNew="isNew"
      :isEdit="isEdit"
      @refresh="refresh"
    />
    <Alert :alert="alert" />
  </div>
</template>

<script>
/* Services */
import OrgService from '@/services/org.service'

/* Components */
import Alert from '@/components/_shared/Alert.vue'
import OrgCard from '@/components/orgs/OrgCard.vue'
import OrgModal from '@/components/orgs/OrgModal.vue'

export default {
  name: 'OrganizationList',
  components: {
    Alert,
    OrgCard,
    OrgModal
  },
  data() {
    return {
      name: '',
      orgs: [],
      total: 0,
      isLoading: true,
      isNew: false,
      isEdit: false,
      selected: 0,
      selectedName: '',
      time: null,
      alert: {}
    }
  },
  mounted() {
    this.loadOrgs()
  },
  methods: {
    loadOrgs: function() {
      if (this.name && this.name.length > 0) {
        console.log('searched:', this.name)
        OrgService.findByName(this.name)
          .then(({ data }) => {
            this.orgs = data.results
            this.total = data.total
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        // fetch orgs from API
        OrgService.getAll().then(({ data }) => {
          // load orgs
          this.orgs = data.results
          this.total = data.total
          this.isLoading = false
        })
      }
    },
    refresh(alert) {
      this.alert = alert
      this.loadOrgs()
    },
    create() {
      this.selected = 0
      this.isNew = true
      this.isEdit = false
      this.time = new Date()
    },
    view(org) {
      this.selected = org.id
      this.selectedName = org.name
      this.isNew = false
      this.isEdit = false
      this.time = new Date()
    },
    edit(org) {
      this.selected = org.id
      this.isNew = false
      this.isEdit = true
      this.time = new Date()
    }
  }
}
</script>
