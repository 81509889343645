<template>
  <div class="navbar">
    <div class="mynav">
      <div class="mynav-block w-100 text-start">
        <router-link to="/">eMet</router-link>
        <router-link v-if="loggedIn" to="/map">Mappa</router-link>
        <router-link v-if="loggedIn" to="/devices">
          Dispositivi
        </router-link>
        <router-link v-if="loggedIn && isAdmin" to="/organizations">
          Organizzazioni
        </router-link>
        <router-link v-if="loggedIn && (isOrganization || isAdmin)" to="/users">
          Utenti
        </router-link>
      </div>
      <div class="mynav-block w-50 text-end">
        <router-link v-if="!loggedIn" to="/login" class="button button-nav">
          Login
        </router-link>
        <div v-else>
          <router-link to="/profile" class="d-inline-block mt-1">
            <RoundImage
              :src="defaultImage"
              :alt="'Logo ' + getFullname()"
              :width="32"
              :has-padding="false"
            />
          </router-link>
          <button
            type="button"
            class="button button-nav float-end"
            @click="logout"
          >
            <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="me-1" />
            Esci
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Libraries */
import { authComputed } from '@/store/helpers.js'

/* Components */
import RoundImage from '@/components/_shared/RoundImage'

export default {
  name: 'AppNav',
  components: {
    RoundImage
  },
  data() {
    return {
      user: {},
      defaultImage:
        'https://icon-library.com/images/default-user-icon/default-user-icon-4.jpg'
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'))
  },
  watch: {
    $route() {
      this.user = JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    ...authComputed
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    getFullname() {
      return this.user == null
        ? ''
        : this.user?.firstName + ' ' + this.user?.lastName
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  z-index: 69;
  width: 100%;
  margin: 0;
  padding: 0;
}
.mynav {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.2em 1em;
  background: linear-gradient(to right, #16c0b0, #84cf6a);
}

.nav-welcome {
  margin-left: auto;
  margin-right: 1rem;
  color: white;
}

a {
  font-weight: bold;
  color: #2c3e50;
  margin: auto 0.8em auto 0.4em;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.router-link-exact-active {
  color: white;
  border-bottom: 2px solid #fff;
}

a.button-nav,
button.button-nav {
  margin-left: auto;
  text-decoration: none;
  border: 0;
  background: none whitesmoke;
  color: #2c3e50;

  &.router-link-active,
  &:hover {
    color: black;
  }
}

.nav-welcome + button {
  margin-left: 0;
}

.user-icon {
  font-size: 1.4em;
}
</style>
